/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { FC } from "react";

type Props = { styles: SerializedStyles };

export const Logo: FC<Props> = ({ styles }) => (
  <svg
    viewBox="0 0 837.533 468.909"
    xmlns="http://www.w3.org/2000/svg"
    css={css`
      justify-self: center;
      width: 20%;
      fill: #1f1a17;
      height: auto;
      ${styles}
    `}
  >
    <path d="M407.53 105.956S294.28 40.429 197.045 65.157c-12.37 3.146-56.634 16.794-90.588 59.782-20.411 25.843-35.537 61.678-37.077 110.707-.889 28.301 17.5 126.314 118.836 165.1 55.883 21.388 155.927-6.174 162.715-11.289l.247-104.743 65.057.181.352 145.865c-26.152 13.008-82.288 30.453-120.808 34.508-78.019 8.215-142.515-2.303-196.097-37.64C83.095 416.69-7.318 351.36.475 226.585 16.24-25.802 282.538-32.438 407.726 39.707M465.05 306.088l65.636.172-51.158 124.573h-60.503l.275-16.545zM710.152 306.11h68.01l59.371 135.894H770.72zM595.064.861l46.998.342 99.921 225.507-67.655-.342-56.562-126.27-52.982 126.435-66.22.177z" />
    <g>
      <path d="M296.886 237.632l451.03-.484 24.197 58.557-457.321-.484z" />
    </g>
  </svg>
);
